<template>
    <div class="testname" id="agentTitle">
	    <Imgbox  v-if="!checking"  src="//cdn.zhiyileiju.cn/WechatIMG123.jpeg" />
		<div v-else style="height: 40px;"></div>
	    <Card  class="formcard">
			<van-form @submit="onSubmit" validate-trigger="onSubmit" @failed="valifail">
				<van-field v-model="Form.username" size="mid" name="username" placeholder="请输入姓名"
					:rules="[{ validator }]">
					<template #label>
						<div class="labelclass">
							您的姓名
						</div>
					</template>
				</van-field>
				<van-field size="mid" name="sex">
					<template #label>
						<div class="labelclass">
							性&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;别
						</div>
					</template>
					<template #input>
						<van-radio-group v-model="Form.sex" direction="horizontal">
							<van-radio name="1" icon-size="18" checked-color="#B95353">男</van-radio>
							<van-radio name="2" icon-size="18" checked-color="#B95353">女</van-radio>
						</van-radio-group>
					</template>
				</van-field>
				<van-field name="borth" size="mid" v-model="boyStr" :rules="[{ required: true}]">
					<template #label>
						<div class="labelclass">出生时间</div>
					</template>
					<template #input>
						<span v-if="boyStr" @click="pickdata">{{boyStr }}</span>
						<span class="llable" v-else @click="pickdata">{{label }}</span>
					</template>
				</van-field>
				<div class="center" style="font-size: 10px;color: #888;margin-top: 12px;" >
					本表单提交代表您已允许我们收集相关信息，且仅用作相关广告内容服务
				</div>
				<div   class="tip">- 仅需9s 给你名字分数 揭晓名字运势 -</div>
				<!-- 表单提交 -->
				<div class="center">
					<van-button class="subbutton" size="large" color="#B95353" type="info" round
						@click="payclick('v1_testname_top_form')" native-type="submit">
						立即测试</van-button>
				</div>
				

				<div  v-if="!checking && !ifvo "  class="center tip">
					已为<span class="color-red" style="font-weight:bold">&nbsp;9,761,918&nbsp;</span>人测试姓名，99.8％人觉得准！
				</div>

				<div v-if="!checking && !ifvo"    class="actionScroll">
					<vue-seamless-scroll :data="listData" :class-option="defaultOption" class="warp">
						<ul class="scrollbox">
							<li class="scrollitem" v-for="(item, index) in listData" :key="index">
								<span class="title123" v-text="item.time_unit"></span>
								<span class="region" style="width: 40px;" v-text="item.city"></span>
								<span class="name1" v-text="item.uname"></span>
								<span class="date" v-text="item.action"></span>
							</li>
						</ul>
					</vue-seamless-scroll>
				</div>
			</van-form>
		</Card>
        <div class="center tith">- 姓名测试后你将了解如下信息 -</div>
	    <Card class="img">
			<Imgbox src="//cdn.zhiyileiju.cn/WechatIMG114.jpeg"></Imgbox>
		</Card>
        <Card v-if="!checking" class="img">
			<Imgbox src="//cdn.zhiyileiju.cn/WechatIMG113.jpeg"></Imgbox>
		</Card>
        <Card class="img">
			<Imgbox src="//cdn.zhiyileiju.cn/WechatIMG112.jpeg"></Imgbox>
		</Card>
         <Card class="img">
			<Imgbox src="//cdn.zhiyileiju.cn/WechatIMG111.jpeg"></Imgbox>
		</Card>
         <Card  v-if="!checking"  class="img">
			<Imgbox src="//cdn.zhiyileiju.cn/WechatIMG110.jpeg"></Imgbox>
		</Card>
         <Card   v-if="!checking && !ifvo " class="img">
			<Imgbox src="//cdn.zhiyileiju.cn/WechatIMG115.jpeg"></Imgbox>
		</Card>
         <Card class="formcard" style="margin-top:20px;">	
             <div class="center" style="padding:16px;font-size:16px;font-weight:bold;color:#C94B4B">姓名测试姓名打分</div>
			<van-form @submit="onSubmit" validate-trigger="onSubmit" @failed="valifail">
				<van-field v-model="Form.username" size="mid" name="username" placeholder="请输入姓名"
					:rules="[{ validator }]">
					<template #label>
						<div class="labelclass">
							您的姓名
						</div>
					</template>
				</van-field>
				<van-field size="mid" name="sex">
					<template #label>
						<div class="labelclass">
							性&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;别
						</div>
					</template>
					<template #input>
						<van-radio-group v-model="Form.sex" direction="horizontal">
							<van-radio name="1" icon-size="18" checked-color="#B95353">男</van-radio>
							<van-radio name="2" icon-size="18" checked-color="#B95353">女</van-radio>
						</van-radio-group>
					</template>
				</van-field>
				<van-field name="borth" size="mid" v-model="boyStr" :rules="[{ required: true}]">
					<template #label>
						<div class="labelclass">出生时间</div>
					</template>
					<template #input>
						<span v-if="boyStr" @click="pickdata">{{boyStr }}</span>
						<span class="llable" v-else @click="pickdata">{{label }}</span>
					</template>
				</van-field>
				<div class="center" style="font-size: 10px;color: #888;margin-top: 12px;" >
					本表单提交代表您已允许我们收集相关信息，且仅用作相关广告内容服务
				</div>
				<div   class="tip">- 仅需9s 给你名字分数 揭晓名字运势 -</div>
				<!-- 表单提交 -->
				<div class="center">
					<van-button class="subbutton" size="large" color="#B95353" type="info" round
						@click="payclick('v1_testname_bottom_form')" native-type="submit">
						立即测试</van-button>
				</div>
				<div  v-if="!checking && !ifvo "   class="center tip">
					已为<span class="color-red" style="font-weight:bold">&nbsp;9,761,918&nbsp;</span>人测试姓名，99.8％人觉得准！
				</div>
			</van-form>
		</Card>
        <van-button :class="fixedbtn == true ? 'isFixed' : ''" size="large" color="#B95353" type="info"
			native-type="submit" @click="bottombtnclick">
			立即测试姓名
		</van-button>
        <rui-datepick :date="currentDate" v-model="boyShow" @confirm="boyConfirm" />
		
		<div  v-if="isbjks"  class="webfooter" style="height:160px;font-size:10px;color:#323232">
			<div>版权所有©2018-2021上海容铭信息科技有限公司</div>
			<div>公司地址：上海市杨浦区抚顺路360号</div>
			<div>电话：18975441215</div>
		</div>
		<div v-else-if="isks4"  class="webfooter" style="height:160px;font-size:10px;color:#323232">
			<div>版权所有 ©2018-2021 武汉道森梯媒文化传播有限公司</div>
			<div>地址：武汉市武昌区徐东大街128号</div>
			<div>电话：18975441215</div>
		</div>
		<div v-else-if="ifFqudao"  class="webfooter" style="height:160px;font-size:10px;color:#323232">
			<div>版权所有 ©2018-2021 苏州特诺维信息技术有限公司</div>
			<div>地址：苏州工业园区星湖街328号创业产业园A1-16</div>
			<div>电话：15695165587</div>
		</div>
		<div v-else-if="ifvo"  class="webfooter" style="height:160px;font-size:10px;color:#323232">
			<div>版权所有 ©2018-2021 深圳市大白网络科技有限公司 </div>
			<div>粤ICP备19105866号</div>
			<div>电话：15695165587</div>
			<div style="font-size: 12px;color: #666666;">{{from == 'tk1'?'VIVO':'OPPO'}}非服务提供商，服务内容与条款以广告主内容为准</div>
		</div>
		<div v-else-if="zylj"  class="webfooter"  style="height:160px;font-size:10px;color:#323232">
			<!-- <div class="synopsis">公司简介：杭州智以类聚科技有限公司是一家专业服务于移动互联网的高新技术企业，集人工智能理论与算法软件开发、一体化整体解决方案、大数据分析等为一体的综合服务商。</div> -->
			<div>版权所有 ©2018-2021 杭州智以类聚科技有限公司</div>
			<div>地址：浙江省杭州市余杭区五常街道期望铭座B座305</div>
			<div>电话：18975441215</div>
		</div>
		<div v-else  class="webfooter" style="height:160px;font-size:10px;color:#323232">
			<div>版权所有 ©2018-2021 湖南亮亮文化传媒有限公司</div>
			<div>地址：长沙市开福区月湖街道鸭子铺路1号1房2室</div>
			<div>电话：18975441215</div>
		</div>
    </div>
</template>

<script>
	import Card from "../components/Card.vue";
    import vueSeamlessScroll from "vue-seamless-scroll";
    import axios from "../utils/request";
	import ruiDatepick from "../components/datepick/index.vue";
	import report from "../utils/stat";
	import router from "../router";

    import {
		RadioGroup,
		Radio,
		Form,
		Field,
		Toast,
	} from "vant";
    export default {
        components:{
           Card, 
           vanForm:Form,
           vanField:Field,
           vanRadioGroup:RadioGroup,
           vanRadio:Radio,
           vueSeamlessScroll,
           ruiDatepick
        },
        data(){
            let currentDate = new Date();
            return {
                Form:{
                   sex: "1",
                   username:"",
                },
                label: "请选择出生时间",
                boyStr:"",
				from: this.$route.query.from,
                boyShow:false,
                fixedbtn:false,
                listData: [{
					action: "正在填写出生日期",
					city: "怒江傈僳族自治州",
					created_at: "2021-07-05 12:06:50",
					id: 1,
					time_unit: "56秒前",
					uname: "矮小扯小蚂蚁",
					updated_at: "2021-07-05 12:06:50",
				}, ],
				currentDate: currentDate.getFullYear() + "-" + (currentDate.getMonth() + 1) + "-" + currentDate.getDate()
            }
        },
        computed:{
            defaultOption() {
				return {
					step: 0.2, // 数值越大速度滚动越快
					limitMoveNum: this.listData.length,
					hoverStop: true, // 是否开启鼠标悬停stop
					direction: 1, // 0向下 1向上 2向左 3向右
					openWatch: false, // 开启数据实时监控刷新dom
					singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
					singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
					waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
				};
			},
			checking(){
				let from = this.$route.query.from;
				let allowfrom = ['ft3','tk2','tk3','tk4','d1','d2','k10','k12'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
			},	
			ifvo(){
				let from = this.$route.query.from;
				let allowfrom = ['tk1','tk2','tk3','tk4'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
			},
			isbjks(){
				let from = this.$route.query.from;
				let allowfrom = ['k4','k5','k6','k7','k10','k12'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
			},
			isks4(){
				let from = this.$route.query.from;
				let allowfrom = ['d1', 'd2', 'd3', 'd4'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
			},
			zylj(){
				let from = this.$route.query.from;
					let allowfrom = ['t1'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
			},
			ifFqudao(){
				let from = this.$route.query.from;
					let allowfrom = ['ft1', 'ft2'];
				if (allowfrom.indexOf(from) > -1) {
					return true
				} else {
					return false
				}
			},
        },
        async created(){
            let data = await this.getactives();
			this.listData = data.data;
			document.title = "诸葛国学测名";
        },
        mounted() {
			window.addEventListener("scroll", this.handleScroll);
			window.localStorage.setItem('fromk', this.$route.query.from);
			report("v1_testname");
		},
        methods:{
          	valifail(errinfo) {
				let errors = errinfo.errors[0]
				if (errors.name == "username") {
					Toast('姓名填写不正确')
				} else {
					Toast('出生日期填写不正确')
				}
			}, 
			payclick(type){
				if (type == 'v1_testname_top_form' && this.bottom_btn_clicked) {
					report('v1_testname_b_top_form');
					return;
				}
				report(type);
			},
			
			isChn(str){
				var han = /^[\u4e00-\u9fa5]{2,8}$/; 
				 if(!han.test(str)){
				   return false ;
				} else {
				   return true ;
				}
			},

            async onSubmit(){
				let birth;
				// if(this.Form.username.length >1){
				//   Toast.fail("当前不支持双姓取名")
				//   return;
				// }
				if (this.birthdata.hour) {
					birth =
						this.birthdata.year +
						"-" +
						this.birthdata.month +
						"-" +
						this.birthdata.day +
						" " +
						this.birthdata.hour +
						":00:00";
				} else {
					birth =
						this.birthdata.year +
						"-" +
						this.birthdata.month +
						"-" +
						this.birthdata.day +
						" 00:00:00";
				}
				let postdata = {
					birth_state:1,
					xname:this.Form.username.slice(0,1),
					name:this.Form.username,
					sex: this.Form.sex,
					pub_birth_date: birth,
					order_type: 1,
					fromk: this.$route.query.from || 10,
					openid:this.$route.query.openid === undefined ? '' : this.$route.query.openid				
				};
		
				
				if(this.$route.query.adid){
					postdata.adId = this.$route.query.adid
				}
				
				if(this.$route.query.requestid){
					postdata.requestId  = this.$route.query.requestid
					postdata.pageUrl = window.location.href;
				}
				if( this.$route.query.key !== undefined ) {
					postdata.key = this.$route.query.key
				}

				if ( this.$route.query.from === 'tk2' ) {
					postdata.tid = this.$route.query.tid
					postdata.lbid = this.$route.query.lbid
					postdata.pageId = this.$route.query.pageId
				}
				
				

				Toast.loading({
					message: '请稍后...',
					forbidClick: true,
				});
				
				let order = await axios.post("/addOrder", postdata);
				Toast.clear();
				router.push({
					name: "aitestnamepay",
					params: {
						id: order.data.data.id,
						type: 10
					},
				}).catch(() => {});
				
            },

            bottombtnclick() {
				document
					.getElementById("agentTitle")
					.scrollIntoView({
						block: "start",
						behavior: "smooth"
					});
				this.bottom_btn_clicked = true;

			},
            handleScroll() {
				var scrollTop =
					window.pageYOffset ||
					document.documentElement.scrollTop ||
					document.body.scrollTop;
				if (scrollTop > 300) {
					this.fixedbtn = true;
				} else {
					this.fixedbtn = false;
				}
			},
            boyConfirm(data) {
				this.birthdata = data;
				this.boyStr = data.thisStr;
			},
            async getactives() {
				let data = await axios.post("/ekaf", {
					type: 3,
					perPage: 10,
					page: 1,
					atype:1
				});
				return data.data;
			},
            validator(val) {
				if (val.length > 1 && val.length < 5 && this.isChn(val)) {
					return true;
				} else {
					return false;
				}
			},
            pickdata(){
			    this.boyShow = true;
            }
        },
       
    }
</script>
<style scoped>
@import "../style/main.css";

.subbutton{
    width: 80vw;
    max-width: 280px;
	animation: scaleDrew 1.5s ease-in-out infinite;
	font-size: 16px;
}

.tith{
    margin: 14px auto;
    font-size: 16px;
    color: #666;
}

.title123{
	width: 70px;
}

.webfooter {
	height: 120px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	/* background: white; */
	line-height: 2em;
	font-size: 14px;
	margin-top: 10px;
	/* background: url("//cdn.zhiyileiju.cn/kexin.png") no-repeat top;
	background-size: 250px;
	background-color: #fff; */
}

.van-cell--mid {
		padding: 12px 16px;
	}
	
	.van-cell{
		font-size: 16px;
	}
	.synopsis{
		padding:14px 4%;
	}

</style>

<style >
	.van-field__control::placeholder,.llable{
		color: #979797 !important;
	}
	
	.datepicker-topinfo__rightbtn{
		width: 80px;
		text-align:end;
	}
</style>


